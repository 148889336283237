import React, { useState, useEffect } from "react";
import { Gift, Snowflake, Star, Bell } from "lucide-react";

// Snowflake animation component
const SnowfallBackground = () => {
  const snowflakes = Array.from({ length: 50 }, (_, i) => ({
    id: i,
    left: `${Math.random() * 100}%`,
    animationDelay: `${Math.random() * 10}s`,
    size: Math.random() * 10 + 5
  }));

  return (
    <div className="fixed inset-0 pointer-events-none z-0 overflow-hidden">
      {snowflakes.map((flake) => (
        <div
          key={flake.id}
          className="absolute text-white opacity-70 animate-snow"
          style={{
            left: flake.left,
            animationDelay: flake.animationDelay,
            fontSize: `${flake.size}px`
          }}
        >
          ❄️
        </div>
      ))}
    </div>
  );
};

// Festive Gift Card Component
const GiftCard = ({ gift }) => {
  const handleWhatsAppClick = () => {
    const message = `🎄 ¡Hola! Estoy interesado en el regalo "${gift.name}" (${gift.value} COP). ¡Feliz Navidad! 🎅`;
    const whatsappUrl = `https://wa.me/573023580862?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="relative bg-white/90 text-red-900 p-6 rounded-xl shadow-2xl border-4 border-green-600 
      transform transition-all duration-300 hover:scale-105 hover:rotate-2 hover:shadow-lg">
      {/* Christmas ribbon */}
      <div className="absolute -top-2 -right-2 w-20 h-20 overflow-hidden">
        <div className="absolute top-0 right-0 w-full h-full bg-red-600 rotate-45 origin-top-left"></div>
        <Star className="absolute top-3 right-3 text-yellow-300" size={24} />
      </div>

      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4">
          <Gift size={36} className="text-green-600 animate-bounce" />
          <h3 className="text-3xl font-bold text-red-800">{gift.name}</h3>
        </div>
      </div>
      
      <p className="text-green-900 text-lg mb-4 italic">{gift.description}</p>
      
      <div className="mt-4 flex items-center justify-between">
        <span className="text-sm font-medium text-red-700 flex items-center">
          <Bell size={16} className="mr-2 text-green-600" />
          Válido hasta: {gift.date}
        </span>
        <div className={`px-3 py-1 rounded-full text-sm font-bold ${
          gift.status === "Disponible" 
            ? "bg-green-500 text-white" 
            : "bg-red-500 text-white opacity-50"
        }`}>
          {gift.status}
        </div>
      </div>

      <button
        onClick={handleWhatsAppClick}
        className={`mt-4 w-full py-3 px-4 rounded-lg transition-all duration-300 
          ${gift.status === "Disponible" 
            ? "bg-green-600 text-white hover:bg-green-700 active:scale-95" 
            : "bg-gray-400 text-gray-200 cursor-not-allowed"}`}
        disabled={gift.status === "Agotado"}
      >
        {gift.status === "Disponible" ? "🎁 Reclamar regalo" : "Agotado"}
      </button>
    </div>
  );
};

// Main Christmas Gifts Component
const ChristmasGifts = () => {
  const [gifts, setGifts] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState("");

  const getCurrentDateTime = () => {
    return new Date().toLocaleString("es-ES", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true
    });
  };

  useEffect(() => {
    // Update date and time every minute
    const updateDateTime = () => {
      setCurrentDateTime(getCurrentDateTime());
    };
    
    updateDateTime(); // Initial call
    const timer = setInterval(updateDateTime, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchedGifts = [
      { 
        id: 1, 
        name: "Usuarios Nuevos", 
        description: "¡3 días gratis de VIP para los recién llegados!", 
        date: "25/12/2024", 
        status: "Disponible" 
      },
      { 
        id: 2, 
        name: "Súper Activo", 
        description: "7 días VIP para el usuario más participativo", 
        date: "25/12/2024", 
        status: "Disponible" 
      },
      { 
        id: 3, 
        name: "Veteranos", 
        description: "5 días gratis para usuarios con más de 2 meses", 
        date: "25/12/2024", 
        status: "Agotado" 
      },
      { 
        id: 4, 
        name: "Seguidores Fieles", 
        description: "¡Hasta 15 días VIP por seguirnos en redes!", 
        date: "25/12/2024", 
        status: "Disponible" 
      },
    ];
    setGifts(fetchedGifts);
  }, []);

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-green-700 to-blue-900 text-white p-8 overflow-hidden">
      {/* Snowfall Background */}
      <SnowfallBackground />

      {/* Content */}
      <div className="relative z-10">
        <div className="text-center mb-12">
          <h2 className="text-5xl font-extrabold mb-4 text-yellow-300 drop-shadow-lg">
            🎄 Regalos Navideños 🎅
          </h2>
          <p className="text-xl text-white/90 max-w-2xl mx-auto">
            Descubre los regalos especiales que tenemos para ti esta Navidad
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 relative z-20">
          {gifts.map((gift) => (
            <GiftCard key={gift.id} gift={gift} />
          ))}
        </div>

        <div className="absolute bottom-4 right-4 text-white/80 text-sm bg-black/20 rounded-lg p-2">
          <p>🕰️ {currentDateTime}</p>
        </div>
      </div>
    </div>
  );
};

export default ChristmasGifts;