import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../Config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import FootballLoading from '../../Utils/FootballLoading';
import { Gift, Snowflake, Ribbon } from 'lucide-react';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Snowflake Background Component
  const ChristmasSnowfall = () => {
    const snowflakes = Array.from({ length: 50 }, (_, i) => ({
      id: i,
      left: `${Math.random() * 100}%`,
      animationDelay: `${Math.random() * 10}s`,
      size: Math.random() * 10 + 5
    }));

    return (
      <div className="fixed inset-0 pointer-events-none z-0 overflow-hidden">
        {snowflakes.map((flake) => (
          <div
            key={flake.id}
            className="absolute text-white opacity-60 animate-snow"
            style={{
              left: flake.left,
              animationDelay: flake.animationDelay,
              fontSize: `${flake.size}px`
            }}
          >
            ❄️
          </div>
        ))}
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      localStorage.setItem('userUUID', user.uid);
      navigate('/home');
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setError('🎄 Credenciales navideñas inválidas. Intenta de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <FootballLoading />;
  }

  return (
    <div className="relative min-h-screen flex items-center justify-center 
      bg-gradient-to-b from-green-700 to-blue-900 overflow-hidden">
      {/* Snowfall Background */}
      <ChristmasSnowfall />

      <div className="relative z-10 bg-white/20 backdrop-blur-lg p-8 rounded-2xl shadow-2xl max-w-md w-full">
        {/* Logo */}
        <div className="flex justify-center mb-6">
          <div className="relative">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
              alt="Logo"
              className="w-32 h-32 rounded-full border-4 border-red-500 
                animate-bounce-slow hover:rotate-6 transition-transform"
            />
            <div className="absolute bottom-2 right-2 
              bg-green-500 w-8 h-8 rounded-full 
              border-4 border-white animate-ping"></div>
          </div>
        </div>

        <h2 className="text-4xl font-bold mb-6 text-center text-yellow-300 drop-shadow-lg">
          🎄 Iniciar Sesión
        </h2>

        {error && (
          <div className="bg-red-500/20 border border-red-500 text-red-300 p-3 rounded-lg mb-4 flex items-center">
            <Gift className="mr-2 text-red-500" />
            {error}
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-white/80 mb-2 flex items-center">
              <Snowflake className="mr-2 text-blue-300" size={20} />
              Correo electrónico:
            </label>
            <input 
              type="email" 
              id="email" 
              className="w-full px-4 py-3 bg-white/20 text-white 
                border border-white/30 rounded-lg 
                focus:outline-none focus:ring-2 focus:ring-yellow-300 
                transition duration-300"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-white/80 mb-2 flex items-center">
              <ChristmasSnowfall className="mr-2 text-green-300" size={20} />
              Contraseña:
            </label>
            <input 
              type="password" 
              id="password" 
              className="w-full px-4 py-3 bg-white/20 text-white 
                border border-white/30 rounded-lg 
                focus:outline-none focus:ring-2 focus:ring-yellow-300 
                transition duration-300"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button 
            type="submit" 
            className="w-full bg-red-600 text-white py-3 rounded-lg 
              hover:bg-red-700 transition duration-300 
              flex items-center justify-center space-x-2 
              transform hover:scale-105"
          >
            <Ribbon className="mr-2" />
            Iniciar Sesión
          </button>
        </form>

        {/* Enlaces de ayuda */}
        <div className="mt-6 text-center">
          <p className="text-white/70">
            <a href="/forgot-password" className="text-yellow-300 hover:text-yellow-200 transition duration-300">
              ¿Olvidaste tu contraseña?
            </a>
          </p>
          <p className="mt-2 text-white/70">
            ¿No tienes una cuenta? 
            <a href="/register" className="text-yellow-300 hover:text-yellow-200 ml-2 transition duration-300">
              Regístrate
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;