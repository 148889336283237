import React, { useState } from 'react';
import { User, Mail, Phone, MapPin, Briefcase, Gift, Snowflake, Star, Ribbon } from 'lucide-react';
import useUserData from '../../Hooks/useUserData';
import FootballLoading from '../../Utils/FootballLoading';

// Snowflake Background Component
const ChristmasSnowfall = () => {
  const snowflakes = Array.from({ length: 50 }, (_, i) => ({
    id: i,
    left: `${Math.random() * 100}%`,
    animationDelay: `${Math.random() * 10}s`,
    size: Math.random() * 10 + 5,
  }));

  return (
    <div className="fixed inset-0 pointer-events-none z-0 overflow-hidden">
      {snowflakes.map((flake) => (
        <div
          key={flake.id}
          className="absolute text-white opacity-60 animate-snow"
          style={{
            left: flake.left,
            animationDelay: flake.animationDelay,
            fontSize: `${flake.size}px`,
          }}
        >
          ❄️
        </div>
      ))}
    </div>
  );
};

const ProfileSection = ({ icon: Icon, title, value, special }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`relative flex items-center space-x-4 p-4 
        bg-gradient-to-br from-green-600 to-red-700 
        rounded-xl shadow-lg transition-all duration-300 
        hover:scale-105 hover:rotate-3 
        ${special ? 'border-4 border-yellow-300 animate-pulse' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {special && (
        <div className="absolute -top-2 -right-2 rotate-45">
          <Gift className="text-yellow-300" size={32} />
        </div>
      )}
      <Icon className="w-8 h-8 text-yellow-300 animate-wiggle" />
      <div className="flex-grow">
        <h3 className="text-sm font-medium text-white/70">{title}</h3>
        <p className="text-lg font-bold text-white">{value}</p>
      </div>
      {isHovered && (
        <div className="absolute bottom-0 left-0 w-full h-1 bg-yellow-300 animate-grow"></div>
      )}
    </div>
  );
};

const UserProfile = () => {
  const { userData, loading, error } = useUserData();

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-green-700 to-blue-900 flex items-center justify-center">
        <FootballLoading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-green-700 to-blue-900 flex items-center justify-center text-white">
        <p>🎄 Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-green-700 to-blue-900 text-white p-3 overflow-hidden">
      <ChristmasSnowfall />
      <div className="relative z-10 max-w-4xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-5xl font-extrabold mb-4 text-yellow-300 drop-shadow-lg">
            🎄 Perfil 🎅
          </h1>
          <p className="text-xl text-white/90 max-w-2xl mx-auto">
            ¡Feliz Navidad, {userData?.name || 'Usuario'}!
          </p>
        </div>

        <div className="relative bg-white/20 backdrop-blur-lg rounded-2xl p-2 shadow-2xl">
          <div className="text-center mb-10">
            <div className="relative inline-block">
              <img
                src="https://avatar.iran.liara.run/public/boy"
                alt="Profile"
                className="w-40 h-40 rounded-full border-4 border-red-500 animate-bounce-slow shadow-xl transform hover:rotate-6 transition-transform"
              />
              <div className="absolute bottom-2 right-2 bg-green-500 w-8 h-8 rounded-full border-4 border-white animate-ping"></div>
            </div>
            <h2 className="mt-6 text-4xl font-bold text-yellow-300 drop-shadow-md">
              {userData?.name || 'Sin identificar'}
            </h2>
            <p className="text-xl text-white/80 mt-2">🎁 {userData?.typeU || 'Sin identificar'}</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <ProfileSection icon={Mail} title="Correo Navideño" value={userData?.email || 'Sin identificar'} />
            <ProfileSection icon={Phone} title="Código de Santa" value={userData?.code || 'Sin identificar'} special />
            <ProfileSection icon={MapPin} title="Amigos Elfos" value={userData?.referralCode || 'Sin identificar'} />
            <ProfileSection
              icon={Briefcase}
              title="Tipo de Cuenta Festiva"
              value={userData?.typeU || 'Sin identificar'}
              special
            />
          </div>
        </div>

        <div className="text-center mt-8">
          <p className="text-white/70 flex items-center justify-center">
            <Star className="mr-2" /> Felices fiestas y próspero año nuevo <Ribbon className="ml-2" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
