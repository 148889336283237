import React, { useState } from 'react';
import { Lock, Mail, User, Snowflake, Gift } from 'lucide-react';
import { auth, database } from '../../Config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import FootballLoading from '../../Utils/FootballLoading';

const generateReferralCode = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let referralCode = '';
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    referralCode += characters[randomIndex];
  }
  return referralCode;
};

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password } = formData;
    
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      localStorage.setItem('userUUID', user.uid);

      const referralCode = generateReferralCode();

      await set(ref(database, 'usuarios/' + user.uid), {
        name: name,
        email: email,
        referralCode: referralCode,
        password: password 
      });
      
      navigate('/home');
    } catch (error) {
      console.error('Error al registrar usuario:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <FootballLoading />;
  }

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-gradient-to-b from-green-900 to-red-900 overflow-hidden">
      {/* Snowfall effect */}
      <div className="absolute inset-0 pointer-events-none" style={{
        background: `
          linear-gradient(to bottom, transparent, transparent 50%, rgba(255,255,255,0.1) 50%),
          linear-gradient(120deg, transparent, transparent 50%, rgba(255,255,255,0.1) 50%)
        `,
        backgroundSize: '50px 50px',
        animation: 'snow 10s linear infinite'
      }} />

      {/* Christmas decorative elements */}
      <Snowflake className="absolute top-10 left-10 text-white opacity-50 animate-bounce" size={40} />
      <Gift className="absolute top-10 right-10 text-white opacity-50 animate-bounce" size={40} />
      <Snowflake className="absolute bottom-10 right-10 text-white opacity-50 animate-bounce" size={40} />
      <Gift className="absolute bottom-10 left-10 text-white opacity-50 animate-bounce" size={40} />

      <div className="relative w-full max-w-md bg-red-900 bg-opacity-70 rounded-lg shadow-2xl p-8 border-4 border-green-600 z-10">
        <div className="flex justify-center mb-6">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
            alt="Logo"
            className="w-24 h-24 rounded-full border-4 border-green-500 animate-pulse"
          />
        </div>
        <h2 className="text-3xl font-bold mb-4 text-center text-white drop-shadow-lg">Crear Cuenta</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-white">Nombre</label>
            <div className="relative">
              <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-green-300" />
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-2 border bg-red-800 text-white border-green-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Ingresa tu nombre"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-white">Correo Electrónico</label>
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-green-300" />
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-2 border bg-red-800 text-white border-green-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Ingresa tu correo electrónico"
                required
              />
            </div>
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-white">Contraseña</label>
            <div className="relative">
              <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-green-300" />
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-2 border bg-red-800 text-white border-green-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Ingresa tu contraseña"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transform hover:scale-105 transition-transform"
          >
            Registrar
          </button>
        </form>
        <div className="mt-4 text-center">
          <p className="text-white">¿Ya tienes una cuenta? <a href="/login" className="text-green-300 hover:underline">Inicia sesión</a></p>
        </div>
      </div>

      {/* Add custom snow animation */}
      <style>{`
        @keyframes snow {
          0% { background-position: 0 0, 0 0; }
          100% { background-position: 50px 100px, 100px 200px; }
        }
      `}</style>
    </div>
  );
};

export default Register;