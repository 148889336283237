import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";

const BaloncestoView = () => {
  const [baloncestoCombinadas, setBaloncestoCombinadas] = useState([]);
  const db = getDatabase();

  useEffect(() => {
    const baloncestoRef = ref(db, "baloncesto"); // Ruta específica para baloncesto

    // Cargar combinadas de baloncesto desde Firebase
    onValue(baloncestoRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedBaloncesto = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setBaloncestoCombinadas(loadedBaloncesto);
      }
    });
  }, [db]);

  return (
    <div className="text-center text-gray-400 mt-8 p-8 bg-gray-800 rounded-lg shadow-lg">
      <h1 className="text-xl font-semibold mb-2 text-white">Predicciones de Baloncesto</h1>
      {baloncestoCombinadas.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {baloncestoCombinadas.map((combinada) => (
            <div
              key={combinada.id}
              className="bg-gray-900 rounded-lg shadow-md p-6"
            >
              <h2 className="text-white font-bold text-lg mb-2">
                {combinada.title}
              </h2>
              <p className="text-gray-300">{combinada.description}</p>
              <div className="text-yellow-400 font-bold mt-4">
                Cuota: {combinada.odds}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <p className="text-lg text-gray-500 mb-4">
            Aún no hay combinadas de baloncesto disponibles en este momento. O Tu plan no incluye .
          </p>
          <a
            href="https://wa.me/573023580862?text=necesito%20una%20combinada%20de%20baloncesto"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-md"
          >
            Pide una combinada de baloncesto
          </a>
        </div>
      )}
    </div>
  );
};

export default BaloncestoView;
