import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { Search, Clock } from "lucide-react";
import { Helmet } from "react-helmet";
import Footer from "../../Utils/Footer";

const getCurrentDateTime = () => {
  const today = new Date();
  return today
    .toLocaleString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(",", "");
};

const PredictionCard = ({ prediction }) => {
  const statusColors = {
    ganada: "bg-green-500",
    pendiente: "bg-orange-500",
    perdida: "bg-red-500",
    nula: "bg-white",
  };

  return (
    <div className="relative bg-gray-800/90 rounded-lg shadow-lg overflow-hidden border-2 border-red-600">
      <div
        className={`absolute inset-y-0 right-0 w-2 ${statusColors[prediction.state] || "bg-gray-500"
          }`}
      ></div>
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4">
            <img
              src={prediction.homeTeam.logo}
              alt={prediction.homeTeam.name}
              className="w-12 h-12 rounded-full border-2 border-green-400"
            />
            <span className="text-red-500 font-semibold text-lg">vs</span>
            <img
              src={prediction.awayTeam.logo}
              alt={prediction.awayTeam.name}
              className="w-12 h-12 rounded-full border-2 border-green-400"
            />
          </div>
          <div className="bg-yellow-400 text-gray-900 font-bold py-1 px-3 rounded-full text-sm animate-jingle">
            {prediction.odds.toFixed(2)}
          </div>
        </div>
        <div className="text-center mb-4">
          <h3 className="text-white font-bold text-lg">
            {prediction.homeTeam.name} vs {prediction.awayTeam.name}
          </h3>
          <p className="text-blue-400 text-sm">{prediction.prediction}</p>
          <div className="flex justify-center items-center text-gray-400 text-sm mt-2">
            <Clock size={16} className="mr-1 text-red-400" />
            <span className="text-white">{prediction.hours}</span>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="w-2/3 bg-gray-700 rounded-full h-4">
            <div
              className="bg-green-500 rounded-full h-4"
              style={{ width: `${prediction.confidence}%` }}
            ></div>
          </div>
          <span className="text-green-500 font-bold">
            {prediction.confidence}%
          </span>
        </div>
        <div className="flex justify-end pt-2">
          <button
            onClick={() => {
              const message = `Hola, tengo una duda sobre el pronóstico: ${prediction.homeTeam.name} vs ${prediction.awayTeam.name}, Predicción: ${prediction.prediction}, Probabilidad: ${prediction.confidence}%, Estado ${prediction.state}`;
              const phoneNumber = "+573023580862";
              const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                message
              )}`;
              window.open(whatsappURL, "_blank");
            }}
            className="bg-blue-600 text-white py-2 px-4 rounded-full shadow-md hover:bg-blue-500 transition-colors duration-300"
          >
            ¿Necesitas ayuda?
          </button>
        </div>
      </div>
    </div>
  );
};

const BetFree = ({ limit }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const abetRef = ref(db, "abet");

    onValue(abetRef, (snapshot) => {
      const data = snapshot.val();
      const loadedPredictions = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));

      const todayPredictions = loadedPredictions.filter(
        (prediction) => prediction.date === currentDateTime.split(" ")[0]
      );

      setPredictions(todayPredictions);
    });
  }, [currentDateTime]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredPredictions = predictions.filter(
    (prediction) =>
      prediction.homeTeam.name.toLowerCase().includes(searchTerm) ||
      prediction.awayTeam.name.toLowerCase().includes(searchTerm) ||
      prediction.prediction.toLowerCase().includes(searchTerm)
  );

  const predictionsToShow = limit
    ? filteredPredictions.slice(0, limit)
    : filteredPredictions;

  return (
    <div className="min-h-screen bg-gradient-to-b from-red-600 to-green-600 text-white relative overflow-hidden">
      <Helmet>
        <title>Predicciones Gratis</title>
      </Helmet>
      {/* Efecto de luces navideñas */}
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
        {[...Array(50)].map((_, i) => (
          <span
            key={i}
            className="absolute w-2 h-2 rounded-full bg-white/70 animate-ping"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 3}s`,
            }}
          />
        ))}
      </div>
      <div className="container mx-auto p-4 relative">
        <div className="mb-6 flex items-center">
          <Search className="text-white mr-2" />
          <input
            type="text"
            placeholder="Buscar predicciones..."
            value={searchTerm}
            onChange={handleSearch}
            className="bg-gray-800 text-white px-4 py-2 rounded-md focus:outline-none w-full"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {predictionsToShow.map((prediction) => (
            <PredictionCard key={prediction.id} prediction={prediction} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BetFree;
