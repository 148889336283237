import React, { useState } from "react";
import { FaDollarSign } from "react-icons/fa";

import {
  Menu,
  X,
  Home as HomeIcon,
  User,
  HelpCircle,
  Award,
  Gem,
  MessageCircle,
  FileClock,
  Trophy,
  TicketPercent,
  Flag,
  CircleDollarSign,
  FileCheck,
  Gift,
} from "lucide-react";
import FootballLoading from "../../Utils/FootballLoading";
import useUserData from "../../Hooks/useUserData";
import UserProfile from "../Profile/UserProfile";
import SupportChat from "../Chat/Chat";
import BetFree from "../Free/BetFree";
import BetVip from "../Vip/BetVip";
import InviteGrupo from "../World/InviteGrupo";
import BetHistory from "../Free/BetHistory";
import { Link } from "react-router-dom";
import Marcadores from "./Marcadores";
import { FaWhatsapp } from "react-icons/fa";
import MessageAlerts from "../../Utils/MessageAlerts";
import { Helmet } from "react-helmet";
import ContactForm from "../Contact/ContactForm";
import { MdContacts } from "react-icons/md";
import HomeShort from "../Shorts/HomeShort";
import { FaRegCirclePlay, FaVolleyball } from "react-icons/fa6";
import SuperPromos from "../promos/SuperPromos";
import HomeBlogs from "../Blogs/HomeBlogs";
import CircularProgressReward from "../../Utils/CircularProgressReward";
import { IoStorefrontSharp } from "react-icons/io5";
import WalletView from "../Wallet/WalletView";
import ButtonTelegram from "../../Utils/ButtonTelegram";
import CustomCarousel from "../ui/CarouselCustomArrows";
import ChristmasGifts from "../../Components/ChristmasGifts";
// import CircularProgressReward from "../../Utils/CircularProgressReward";

const Home = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentView, setCurrentView] = useState("home");
  const { userData, loading, error } = useUserData();

  // Muestra un loading mientras se carga la información del usuario
  if (loading) return <FootballLoading />;
  if (error) return <p>Error: {error}</p>;

  // Función para alternar la visibilidad del menú lateral
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Menú de navegación lateral
  const MenuItem = ({ icon: Icon, text, view, children }) => (
    <li
      className="flex items-center justify-between p-3 hover:bg-indigo-600 rounded-lg transition-colors duration-200 cursor-pointer"
      onClick={() => {
        setCurrentView(view);
        setIsMenuOpen(false);
      }}
    >
      <div className="flex items-center space-x-3">
        <Icon className="w-5 h-5 text-white" />
        <span className="text-white font-semibold">{text}</span>
      </div>
      {children && <div>{children}</div>}
    </li>
  );

  // Renderiza el contenido de acuerdo a la vista seleccionada
  const renderContent = () => {
    switch (currentView) {
      case "profile":
        return <UserProfile />;
      case "help":
        return <SupportChat />;
      case "bet":
        return <Home limit={userData ? undefined : 3} />;
      case "vip":
        return <BetVip />;
      case "whatsapp":
        return <InviteGrupo />;
      case "inplay":
        return <Marcadores />;
      case "history":
        return <BetHistory />;
      case "contact":
        return <ContactForm />;
      case "ofertas":
        return <SuperPromos />;
      case "combi":
        return <BetVip />;
      case "baloncesto":
        return <betVip />;
      case "regalos":
        return <ChristmasGifts/>
      case "shorts":
        return <HomeShort />;

      default:
        // Muestra máximo 2 pronósticos si el usuario no está autenticado
        return (
          <>
            <CustomCarousel />
            <BetFree limit={userData ? undefined : 3} />
            {!userData && (
              <div className="fixed bottom-0 left-0 w-full bg-gray-900 p-6 shadow-lg flex flex-col items-center z-50">
                <p className="text-gray-300 mb-4 text-lg font-semibold text-center">
                  Inicie sesión o cree una cuenta para ver más pronósticos
                </p>
                <div className="flex space-x-4">
                  <Link to="/login">
                    <button className="bg-gradient-to-r from-blue-600 to-blue-500 text-white font-bold px-6 py-3 rounded-full transition-transform transform hover:scale-105 hover:shadow-lg">
                      Iniciar sesión
                    </button>
                  </Link>
                  <Link to="/register">
                    <button className="bg-gradient-to-r from-green-600 to-green-500 text-white font-bold px-6 py-3 rounded-full transition-transform transform hover:scale-105 hover:shadow-lg">
                      Crear cuenta
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </>
        );
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col">
      {/* Header Navideño */}
      <header className="fixed top-0 left-0 w-full 
  bg-gradient-to-r from-green-700 to-red-700 
  p-4 shadow-lg z-50 
  border-b-4 border-yellow-400 
  overflow-hidden
  relative">
        {/* Efecto de luces navideñas */}
        <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
          {[...Array(20)].map((_, i) => (
            <span
              key={i}
              className="absolute w-2 h-2 rounded-full bg-white/70 animate-ping"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 2}s`
              }}
            />
          ))}
        </div>

        <div className="container mx-auto flex justify-between items-center relative z-10">
          <div className="flex items-center space-x-4">
            {/* Avatar con efecto de adorno navideño */}
            <div className="relative">
              <img
                src="https://avatar.iran.liara.run/public/boy"
                alt="Avatar"
                className="w-10 h-10 rounded-full object-cover 
            border-4 border-yellow-300 
            animate-bounce"
              />
              {/* Decoración de árbol de Navidad */}
              <span className="absolute -top-2 -right-2 text-xl">🎄</span>
            </div>

            {/* Nombre con efecto navideño */}
            <h1 className="text-white text-3xl font-extrabold 
        animate-pulse 
        drop-shadow-[0_0_5px_rgba(255,255,255,0.7)]
        flex items-center">
              {userData ? userData.name.split(" ")[0] : "Usuario"}
              <span className="ml-2 text-xl">🎅</span>
            </h1>
          </div>

          <div className="flex items-center space-x-4">
            {/* Botón de menú con tema navideño */}
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none lg:hidden 
          bg-red-600 rounded-full p-2 
          hover:bg-green-600 
          transition-all duration-300 
          transform hover:rotate-12"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <X className="w-6 h-6 animate-wiggle" />
              ) : (
                <Menu className="w-6 h-6 animate-jingle" />
              )}
            </button>
          </div>
        </div>

        {/* Efecto de nieve */}
        <div className="absolute bottom-0 left-0 w-full h-10 
    pointer-events-none overflow-hidden">
          {[...Array(50)].map((_, i) => (
            <span
              key={i}
              className="absolute w-1 h-1 bg-white/80 rounded-full 
          animate-fall"
              style={{
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 5}s`
              }}
            />
          ))}
        </div>
      </header>

      {/* Estilos personalizados para animaciones */}
      <style jsx>{`
  @keyframes fall {
    0% { 
      transform: translateY(-10vh) rotate(0deg);
      opacity: 0.7;
    }
    100% { 
      transform: translateY(100vh) rotate(360deg);
      opacity: 0;
    }
  }

  @keyframes jingle {
    0%, 100% { transform: rotate(0deg); }
    25% { transform: rotate(-10deg); }
    75% { transform: rotate(10deg); }
  }

  @keyframes wiggle {
    0%, 100% { transform: rotate(0deg); }
    25% { transform: rotate(-15deg); }
    75% { transform: rotate(15deg); }
  }

  .animate-fall {
    animation: fall linear infinite;
  }

  .animate-jingle {
    animation: jingle 0.5s infinite;
  }

  .animate-wiggle {
    animation: wiggle 0.5s infinite;
  }
`}</style>

      <div className="flex flex-1 overflow-hidden">
      <div
      className={`fixed lg:static top-0 left-0 h-full w-64 
        bg-gradient-to-b from-green-800 to-red-800 
        text-white p-6 transform transition-transform duration-300 ease-in-out z-20 
        border-r-4 border-yellow-400
        ${isMenuOpen ? "translate-x-0" : "-translate-x-full"} 
        lg:translate-x-0`}
    >
       {/* Efecto de luces navideñas */}
       <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
          {[...Array(20)].map((_, i) => (
            <span
              key={i}
              className="absolute w-2 h-2 rounded-full bg-white/70 animate-ping"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 2}s`
              }}
            />
          ))}
        </div>

      <div className="flex justify-between items-center mb-8 lg:hidden">
        <h2 className="text-2xl font-extrabold flex items-center">
          Menú 🎄
        </h2>
        <button
          onClick={toggleMenu}
          className="text-white focus:outline-none hover:rotate-12 transition-transform"
        >
          <X className="w-6 h-6 animate-wiggle" />
        </button>
      </div>
      <nav>
        <ul className="space-y-4">
          <MenuItem icon={HomeIcon} text="Inicio" view="home">
            <span className="bg-green-600 text-xs rounded-full px-2 py-1 border-2 border-white/30 
              hover:bg-green-500 transition-all duration-300 
              animate-pulse hover:animate-none">
              🏠
            </span>
          </MenuItem>
          <MenuItem icon={Gift} text="Regalos" view="regalos">
            <span className="bg-green-600 text-xs rounded-full px-2 py-1 border-2 border-white/30 
              hover:bg-green-500 transition-all duration-300 
              animate-pulse hover:animate-none">
              🎁
            </span>
          </MenuItem>
          <MenuItem icon={Gem} text="Vip" view="vip">
            <span className="bg-green-600 text-xs rounded-full px-2 py-1 
              border-2 border-yellow-300 
              hover:bg-green-500 hover:scale-105 transition-all duration-300 
              shadow-md hover:shadow-lg">
              +99%🔥
            </span>
          </MenuItem>
          <MenuItem icon={FileCheck} text="Combinadas" view="combi">
            <span className="bg-purple-600 text-xs rounded px-2 py-1 
              border-2 border-white/30 
              hover:bg-purple-500 hover:rotate-6 transition-all duration-300 
              shadow-md">
              🤑
            </span>
          </MenuItem>
          <MenuItem icon={FaVolleyball} text="Baloncesto" view="combi">
            <span className="bg-white text-black text-xs rounded px-2 py-1 
              border-2 border-red-300 
              hover:bg-gray-100 hover:scale-110 transition-all duration-300 
              shadow-md">
              🏀
            </span>
          </MenuItem>
          <MenuItem icon={User} text="Perfil" view="profile">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setCurrentView("profile-edit");
              }}
              className="bg-blue-600 text-xs rounded px-2 py-1 
                border-2 border-white/30 
                hover:bg-blue-500 hover:scale-105 transition-all duration-300 
                shadow-md transform active:scale-95"
            >
              Editar 🎅
            </button>
          </MenuItem>
          <MenuItem icon={Trophy} text="Marcadores" view="inplay">
            <span className="bg-red-600 text-xs rounded-full px-2 py-1 
              border-2 border-yellow-300 
              hover:bg-red-500 hover:animate-pulse transition-all duration-300 
              shadow-md">
              En vivo
            </span>
          </MenuItem>
          <MenuItem icon={TicketPercent} text="Ofertas" view="ofertas">
            <span className="bg-slate-600 text-xs rounded px-2 py-1 
              border-2 border-white/30 
              hover:bg-slate-500 hover:rotate-6 transition-all duration-300 
              shadow-md">
              % 💰
            </span>
          </MenuItem>
          <MenuItem
            icon={MessageCircle}
            text="Grupo WhatsApp"
            view="whatsapp"
          >
            <span className="text-xs text-green-300 
              bg-white/20 rounded px-2 py-1 
              border-2 border-green-300 
              hover:bg-white/30 transition-all duration-300 
              animate-bounce hover:animate-none">
              🎄
            </span>
          </MenuItem>
          <MenuItem icon={MdContacts} text="Contacto" view="contact">
            <span className="bg-orange-600 text-xs rounded-full px-2 py-1 
              border-2 border-white/30 
              hover:bg-orange-500 hover:scale-105 transition-all duration-300 
              shadow-md">
              Nuevo
            </span>
          </MenuItem>
          <MenuItem icon={HelpCircle} text="Ayuda" view="help">
            <span className="text-xs text-gray-300 
              bg-white/20 rounded px-2 py-1 
              border-2 border-gray-300 
              hover:bg-white/30 transition-all duration-300">
              FAQ
            </span>
          </MenuItem>
        </ul>
      </nav>
      <style jsx>{`
         @keyframes fall {
    0% { 
      transform: translateY(-10vh) rotate(0deg);
      opacity: 0.7;
    }
    100% { 
      transform: translateY(100vh) rotate(360deg);
      opacity: 0;
    }
  }

  @keyframes jingle {
    0%, 100% { transform: rotate(0deg); }
    25% { transform: rotate(-10deg); }
    75% { transform: rotate(10deg); }
  }

  @keyframes wiggle {
    0%, 100% { transform: rotate(0deg); }
    25% { transform: rotate(-15deg); }
    75% { transform: rotate(15deg); }
  }

  .animate-fall {
    animation: fall linear infinite;
  }

  .animate-jingle {
    animation: jingle 0.5s infinite;
  }

  .animate-wiggle {
    animation: wiggle 0.5s infinite;
  }
          
      `}</style>
    </div>



        {/* Main Content */}
        <main className="flex-1 overflow-y-auto pt-8">
          <div className="container mx-auto">{renderContent()}</div>
          {/* SEO Optimized Helmet */}
          <Helmet>
            <title>Pronósticos Deportivos | DeportivosPro</title>
            <meta
              name="description"
              content="Consulta pronósticos deportivos actualizados y obtén los resultados de los partidos en vivo. Con DeportivosPro, tendrás análisis experto para tus deportes favoritos."
            />
            <meta
              name="keywords"
              content="pronósticos deportivos, resultados en vivo, fútbol, análisis de partidos, predicciones deportivas, deportes"
            />
            <meta name="author" content="DeportivosPro" />
            <meta name="robots" content="index, follow" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="canonical" href="https://deportivospro.online" />

            {/* Open Graph Meta Tags for Social Media Sharing */}
            <meta
              property="og:title"
              content="Inicio - Pronósticos Deportivos | DeportivosPro"
            />
            <meta
              property="og:description"
              content="Obtén pronósticos deportivos actualizados y los resultados de tus partidos favoritos en vivo con análisis de expertos en DeportivosPro."
            />
            <meta property="og:url" content="https://deportivospro.online/" />
            <meta property="og:type" content="website" />
            <meta
              property="og:image"
              content="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
            />

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Inicio - Pronósticos Deportivos | DeportivosPro"
            />
            <meta
              name="twitter:description"
              content="Consulta los pronósticos deportivos más acertados y sigue los partidos en vivo con DeportivosPro."
            />
            <meta
              name="twitter:image"
              content="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
            />
          </Helmet>
        </main>
      </div>

      {/* Overlay for mobile */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10 lg:hidden"
          onClick={toggleMenu}
        ></div>
      )}
      <MessageAlerts />
    </div>
  );
};

export default Home;
